<template>
    <header class="page-head rd-navbar-wrap header_corporate">
        <nav class="rd-navbar" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fullwidth" data-md-layout="rd-navbar-fullwidth" data-lg-layout="rd-navbar-fullwidth" data-device-layout="rd-navbar-fixed" data-sm-device-layout="rd-navbar-fixed" data-md-device-layout="rd-navbar-fixed"
             data-lg-device-layout="rd-navbar-fullwidth" data-stick-up-offset="100px">
            <!--RD Navbar Panel-->
            <div class="rd-navbar-top-panel bg-header-color-client">
                <div class="rd-navbar-top-panel-wrap bg-header-color-client">
                    <div class="top-panel-inner">
                        <dl class="dl-horizontal-mod-1">
                            <dt>
                                <span style="color: white" class="material-icons-local_phone icon-xxs-mod-2 text-white"></span>
                            </dt>
                            <dd>
                                <a class="text-white" href="callto:#">05 63 73 16 97</a>
                            </dd>
                        </dl>
                        <dl class="dl-horizontal-mod-1">
                            <dt>
                                <span style="color: white" class="material-icons-drafts icon-xxs-mod-2"></span>
                            </dt>
                            <dd>
                                <a class="text-white" href="mailto:#">contact@abs-group.fr</a>
                            </dd>
                        </dl>
                        <address>
                            <dl class="dl-horizontal-mod-1">
                                <dt>
                                    <span style="color: white" class="mdi mdi-map-marker-radius icon-xxs-mod-2"></span>
                                </dt>
                                <dd>
                                    <span style="color: white;font-size: 14px;font-weight: 400!important;">Arteparc – Bât B
                                        Route de la Côte d’Azur
                                        - Lieudit Le Canet de Meyreuil -
                                        13590 MEYREUIL
                                    </span>
                                </dd>
                            </dl>
                        </address>
                    </div>
                    <ul class="list-inline">
                        <li>
                          <a href="https://www.linkedin.com/company/AutoBilan-Systems/" target="_blank">
                            <v-icon size="25" style="color: white">
                              {{ icons.mdiLinkedin }}
                            </v-icon>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.facebook.com/AutoBilan.Systems/" target="_blank">
                            <v-icon size="25" style="color: white">
                                {{ icons.mdiFacebook }}
                            </v-icon>
                          </a>
                        </li>
                        <li>
                          <a href="">
                              <a href="https://goo.gl/maps/svyAZkSK7hZor5ED9" target="_blank">
                                <v-icon size="25" style="color: white">
                                  {{ icons.mdiGoogle }}
                                </v-icon>
                              </a>
                          </a>
                        </li>
                    </ul>
                    <div class="btn-group">
                        <a href="submit-property.html" class="btn btn-sm btn-primary">Submit Property</a>
                    </div>
                </div>
            </div>
            <div class="rd-navbar-inner inner-wrap">
                <div class="rd-navbar-panel">
                    <!-- RD Navbar Toggle-->
                    <button data-rd-navbar-toggle=".rd-navbar-nav-wrap" class="rd-navbar-toggle">
                        <span></span>
                    </button>
                    <!-- RD Navbar Brand-->
                    <div class="rd-navbar-brand">
                        <router-link to="/">
                            <img src="/images/ABS_Group_72ppi.png" style="width: 240px; height: 41px; padding-top: 10px" alt="">
                        </router-link>
                    </div>
                </div>

                <div class="rd-navbar-nav-wrap">
                    <!-- RD Navbar Nav-->
                    <ul class="rd-navbar-nav">
                        <li>
                            <router-link to="/">
                                Prendre un rendez-vous
                            </router-link>
                        </li>

<!--                        <li>-->
<!--                            <router-link  to="/budgetct">Mon Budget CT</router-link>-->
<!--                        </li>-->

                        <li>
                            <router-link  to="/alertect" >Mon Alerte CT</router-link>
                        </li>

                        <li>
                            <router-link to="/recruit">Les centres recrutent</router-link>
                        </li>
                        <li class="justify-end SwapStatusclient-li">
                            <SwapStatusClient class="SwapStatusclient"></SwapStatusClient>
                        </li>
                        <li class="rd-navbar-bottom-panel">
                            <div class="rd-navbar-bottom-panel-wrap">
                                <div class="top-panel-inner">
                                    <dl class="dl-horizontal-mod-1">
                                        <dt>
                                            <span class="icon-xxs-mod-2 material-icons-local_phone"></span>
                                        </dt>
                                        <dd>
                                            <a href="callto:#"> 05 63 73 16 97 - 05 63 59 35 42</a>
                                        </dd>
                                    </dl>
                                    <dl class="dl-horizontal-mod-1">
                                        <dt>
                                            <span class="material-icons-drafts icon-xxs-mod-2"></span>
                                        </dt>
                                        <dd>
                                            <a href="mailto:#"> contact@autobilan-systems.com</a>
                                        </dd>
                                    </dl>
                                    <address>
                                        <dl class="dl-horizontal-mod-1">
                                            <dt>
                                                <span class="icon-xxs-mod-2 mdi mdi-map-marker-radius"></span>
                                            </dt>
                                            <dd>
                                                <a href="#" class="inset-11">Arteparc – Bat B
                                                    Route de la Cote d’Azur
                                                    Lieudit Le Canet de Meyreuil
                                                    13590 MEYREUIL</a>
                                            </dd>
                                        </dl>
                                    </address>
                                </div>
                                <ul class="list-inline">
                                    <li>
                                        <a href="#" class="fa-facebook"></a>
                                    </li>
                                    <li>
                                        <a href="#" class="fa-twitter"></a>
                                    </li>
                                    <!-- <li>
                                         <a href="#" class="fa-pinterest-p"></a>
                                     </li>
                                     <li>
                                         <a href="#" class="fa-vimeo"></a>
                                     </li>-->
                                    <li>
                                        <a href="#" class="fa-google"></a>
                                    </li>
                                    <!--<li>
                                        <a href="#" class="fa-rss"></a>
                                    </li>-->
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
    import SwapStatusClient from "@/components/SwapStatusClient";
    import { mdiFacebook, mdiLinkedin, mdiGoogle } from '@mdi/js';

    export default {
        name: "MainHeaderMenu",
        directives:{
            'add-class-hover':{
                bind(el, binding, vnode) {
                    const { value="" } = binding;
                    el.addEventListener('mouseenter',()=> {
                        el.classList.add(value)
                    });
                    el.addEventListener('mouseleave',()=> {
                        el.classList.remove(value)
                    });
                },
                unbind(el, binding, vnode) {
                    el.removeEventListener('mouseenter');
                    el.removeEventListener('mouseleave')
                }
            }
        },
        data(){
            return{
                icons: {
                  mdiFacebook,
                  mdiLinkedin,
                  mdiGoogle
                },
                VL: [
                    {
                        id: 1,
                        nameUrl:'suivi&rachat',
                        name: "Suivi & Rachat"
                    },
                    {
                        id: 2,
                        nameUrl:'systeme-qualite',
                        name: "Système qualité"
                    },
                    {
                        id: 3,
                        nameUrl:'veille-reglementaire',
                        name: "Veille règlementaire"
                    },
                    {
                        id: 4,
                        nameUrl:'assistance',
                        name: "Assistance"
                    },
                    {
                        id: 5,
                        nameUrl:'proces-verbaux',
                        name: "Procès-verbaux"
                    },
                    {
                        id: 6,
                        nameUrl:'audits&formations',
                        name: "Audits & Formations"
                    },
                ],
                PL:
                    [
                        {
                            id: 1,
                            nameUrl:'suivi&rachat',
                            name: "Suivi & Rachat"
                        },
                        {
                            id: 2,
                            nameUrl:'systeme-qualite',
                            name: "Système qualité"
                        },
                        {
                            id: 3,
                            nameUrl:'veille-reglementaire',
                            name: "Veille règlementaire"
                        },
                        {
                            id: 4,
                            nameUrl:'assistance',
                            name: "Assistance"
                        },
                        {
                            id: 5,
                            nameUrl:'proces-verbaux',
                            name: "Procès-verbaux"
                        },
                        {
                            id: 6,
                            nameUrl:'audits&formations',
                            name: "Audits & Formations"
                        },
                    ],
                Informatique:
                    [
                        {
                            id: 1,
                            nameUrl:'mercure-vl',
                            name: "Mercure VL"
                        },
                        {
                            id: 2,
                            nameUrl:'mercure-pl',
                            name: "Mercure PL"
                        },
                        {
                            id: 3,
                            nameUrl:'mercure-rdv',
                            name: "Mercure RDV"
                        },
                        {
                            id: 4,
                            nameUrl:'mercure-web',
                            name: "Mercure Web"
                        },
                        {
                            id: 5,
                            nameUrl:'mercure-qualité',
                            name: "Mercure Qualité"
                        },
                        {
                            id: 6,
                            nameUrl:'mercure-access',
                            name: "Mercure Access"
                        },
                    ],
                Societe:
                    [
                        {
                            id: 1,
                            nameUrl:'savoir-faire',
                            name: "Notre savoir-faire"
                        },
                        {
                            id: 2,
                            nameUrl:'controle-technique',
                            name: "Le contrôle technique"
                        },
                        {
                            id: 3,
                            nameUrl:'actualite',
                            name: "Actualités"
                        },
                        {
                            id: 4,
                            nameUrl:'partenaires',
                            name: "Partenaires"
                        }
                    ],
                Formations:
                    [
                        {
                            id: 0,
                            nameUrl:'maintien-qualification-controleurs',
                            name: "Maintien de qualification contrôleurs"
                        },
                        {
                            id: 1,
                            nameUrl:'remise-a-niveau-controleurs',
                            name: "Remise à niveau contrôleurs"
                        },
                        {
                            id: 2,
                            nameUrl:'qualification-controle-gpl',
                            name: "Qualification pour le contrôle des véhicules GPL"
                        },
                        {
                            id: 3,
                            nameUrl:'habilitation-B2XL',
                            name: "Risques électriques pour habilitation B2XL"
                        },
                        {
                            id: 4,
                            nameUrl:'exploitants-centres',
                            name: "Exploitants centres"
                        },
                        {
                            id: 5,
                            nameUrl:'formation-initiale-controleur',
                            name: "Formation initiale contrôleur"
                        },
                        {
                            id: 6,
                            nameUrl:'formation-levage',
                            name: "Formation levage"
                        },
                    ],
                Audits:
                    [
                        {
                            id: 1,
                            nameUrl:'audits-initiaux',
                            name: "Audits initiaux centres VL & PL"
                        },
                        {
                            id: 2,
                            nameUrl:'audits-reglementaires',
                            name: "Audits règlementaires centres VL & PL"
                        },
                        {
                            id: 3,
                            nameUrl:'audits-controleurs',
                            name: "Audits règlementaires contrôleurs VL & PL"
                        },
                        {
                            id: 4,
                            nameUrl:'audits-internes',
                            name: "Audits internes"
                        },
                    ],
            }
        },
        components:{
            SwapStatusClient,
        },
        methods:{

        },
    }
</script>

<style scoped>


    @media (min-width: 1800px) {
        .SwapStatusclient-li{
            display: block;
            display: inline-block;
            position: absolute;
        }
        .SwapStatusclient{
            width: 50px;
        }
    }

    @media (min-width: 768px) and (max-width: 1799px) {
        .rd-navbar-nav-wrap{
            padding: 0 0 0 0;
            display: block;

        }
        .rd-navbar-fullwidth .rd-navbar-inner {
            padding: 0 0 0 0;
        }
        .rd-navbar-nav>li>a {
            font-size: 13px;
            margin: 0 0 0 0;
            padding: 0 0 0 0;

        }

    }
    @media (min-width: 768px) and (max-width: 1125px) {
        .rd-navbar-nav>li>a {
            font-size: 11px;
            margin: 0 0 0 0;
            padding: 0 0 0 0;

        }
    }

    @media (min-width: 768px) and (max-width: 1010px) {
        .rd-navbar-nav>li>a {
            font-size: 9px;
            margin: 0 0 0 0;
            padding: 0 0 0 0;

        }
    }


    @media (min-width: 768px) and (max-width: 900px) {
        .rd-navbar-nav>li>a {
            font-size: 7px;
            margin: 0 0 0 0;
            padding: 0 0 0 0;

        }
    }

.text-white{
    color: white;
}

.hover-menu{
    text-decoration: underline;
    text-decoration-color: var(--link-color);
}
    .bg-header-color-pro{
        background-color: #89B951!important;
    }
    .bg-header-color-client{
        background-color: #025578!important;
    }
    .bg-text-color-client{
        color: #025578!important;
    }
    .bg-text-color-pro{
        color: #89B951!important;
    }
    .color-pro_li{
        color:black!important;
    }
    .hover-submenu{
        color: #F2811D!important;
    }
    .button-centre{
        font-size: 12px;
        font-weight: 200px;
        letter-spacing: 0.5px;
        color: #89B951!important;
    }
</style>
