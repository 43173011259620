import axios from "axios"

export const paymentStore= {
    state:{

    },
    getters:{

    },
    mutations:{

    },
    actions:{
        async payment(state){
            const result = await axios.post("http://127.0.0.1:3000/payment")
        },
        async initPayment(state,object){
            const response = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}init`,object))
            return response.data
        },
        async getFormPaymentAlma(state,object){
            const response = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}payment-alma`,object))
            return response
        },
        async getPaymentDetailLW(state,object){
          const response = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}payment-detaillw`,object))
            return response.data
        },
        async getAlmaPaymentDetail(state,object){
            const response = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}alma-detail`,object))
            return response.data
        },
        async initBctPayment(state,object){
            const response = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}bct-init`,object))
            return response.data
        },
        async initBctRec(state,reference){
            ///bct-init-recur
            const response = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}bct-init-recur`,reference))
            return response.data
        }
    }
}
