<template>
  <v-app>
  <Home></Home>
  </v-app>
</template>

<script>
import Home from "@/pages/HomePage";
export default {
  name: 'App',

  components: {
    Home,
  },

  data: () => ({
    //
  }),
  async mounted() {
  }
};
</script>
<style>
a {
    color: var(--btn-color);
    
}

a:hover{
  color: #F2811D!important;
  font-weight: 700;
}

button {
   /* background-color: var(--btn-color);*/
    color: white;
    padding: 12px 23px;
    border: 1px solid transparent;
    margin: 5px;
    border-radius: 6px;
    text-transform: uppercase;
    font-size: .75rem;
    font-family: Roboto, sans-serif;
    font-weight: bold;
}

</style>
