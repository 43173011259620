<template>
    <v-container fluid class="cookie ma-0 pa-0" v-if="!statusCookie">
            <v-col class="col-12 abs-group">
                <div class="wrapper-cookie">
                    <div  >
                        <p class="ma-0 pa-0" style="color: white">{{text}}</p>
                    </div>
                    <div >
                        <router-link to="/informations-legales/politiques-des-cookies">
                          <button @click.prevent="preventDefault" small class="mr-lg-2 mr-md-4 mr-sm-6 text-caption button-cookie" >
                            En savoir plus
                          </button>
                        </router-link>
                    </div>
                    <div >
                        <button @click.prevent="preventDefault" small class="ml-lg-2 ml-md-4 ml-sm-6 text-caption button-cookie"  @click="acceptCookie">
                            J'accepte
                        </button>
                    </div>
                </div>
            </v-col>
    </v-container>
</template>

<script>
import {preventDefault} from '@/libraries/general'
    export default {
        name: "CookieMessageComponent",
        data(){
            return{
                text:"Ce site utilise des cookies pour vous offrir le meilleur service. En poursuivant votre navigation, vous acceptez l’utilisation des cookies.",
                statusCookie:false,
            }
        },
        methods:{
            preventDefault,
            acceptCookie(){
                localStorage.setItem('GPDR:accepted',true)
                this.statusCookie =true
            },
            isCookiesAccepted(){
                return localStorage.getItem('GPDR:accepted') == 'true' ? true : false
            }
        },
        mounted() {
            this.statusCookie = this.isCookiesAccepted()
        }
    }
</script>

<style scoped>
    .cookie{
        position: fixed;
        bottom: 0;
        z-index: 9999999;
    }
    .abs-group{
        background-color: #1F5578!important;
    }
    .auto-bilan-systems{
        background-color: #89BA51!important;
    }
    .consent{
        background-color: #3386A6;
    }
    .color-cookie{
        background-color: #EEF0F2;
    }
    .text-abs{
        color:#89BA51!important;
    }
    .text-abs-group{
        color:#1F5578!important;
    }
    .button-cookie{
        background-color: #f5f5f5;
        color: black;
        padding: 0.5em 0.5em;
        min-width:50px;
    }
    .wrapper-cookie{
        display:flex;
        justify-content: center;
        align-items: center;
    }
</style>
