const alertectPage = () => import(/* webpackChunkName: "AlertCtPage"*/"@/pages/AlerteCtPage")
// const budgetCtPage = () => import(/* webpackChunkName: "BudgetCtPage"*/"@/pages/BudgetControleTechniquePage")
const ReturnPaymentComponent = () => import(/* webpackChunkName: "ReturnPayment"*/"@/components/payment/ReturnPaymentComponent")
const bctSpace = () => import(/* webpackChunkName: "BtcSpacePage"*/"@/components/client/bctSpace")
const recruitPage = () => import(/* webpackChunkName: "RecruitPage"*/"@/pages/FormRecruitPage")
const ParticulierPage = () => import(/* webpackChunkName: "HomePage"*/"@/pages/ParticulierPage")
const ContactPage = () => import(/* webpackChunkName: "ContactPage"*/"@/pages/ContactPage")
const informationsLegalesPage = () => import(/* webpackChunkName: "InformationsLegagesPage"*/"@/pages/InformationsLegalesPage")
const ActualitePage = () => import(/* webpackChunkName: "ActualitePage"*/"@/pages/ActualitePage")
export const particulierRoutes = [
    {
        path: '/',
        name: 'particulier',
        component: ParticulierPage
    },
    {
        path: '/contact',
        name:'contact',
        component: ContactPage
    },
    {
        path: '/informations-legales/:nameUrl?',
        props: true,
        name: 'info-legal',
        component: informationsLegalesPage
    },
    {
      path:'/perso-user',
      name:'perso-user',
      component:bctSpace
    },
    {
        path:'/return-payment/:data?',
        name: 'return-payment',
        component: ReturnPaymentComponent
    },
    // {
    //     path: '/budgetct/:token?',
    //     name: 'BudgetCT',
    //     component: budgetCtPage,
    // },
    {
        path: '/alertect',
        name: 'alertect',
        component: alertectPage
    },
    {
        path:'/recruit',
        name:'recruit',
        component:recruitPage
    },
    {
        path: '/actualites/:nameUrl?',
        props: true,
        name: 'actualites',
        component: ActualitePage
    },
]
