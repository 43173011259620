import axios from "axios";


export const userStore = {
    state:{
        authenticate: false,
        tokenPaymentClient:'',
        token:'',
        user:''
    },
    getters:{
        token : state => state.token,
        authenticate: state => state.authenticate,
        tokenPaymentClient: state => state.tokenPaymentClient,
        user:state=> state.user
    },
    mutations:{
        setTokenPaymentclient(state,token){
          state.tokenPaymentClient = token
        },
        setToken(state,data){
            state.token = data
            state.authenticate = true
            localStorage.setItem("token",state.token)
        },
        setIsAuth(state,user){
            state.user = user
            state.authenticate= true
        },
        setIsNotAuth(state){
            state.user = ''
            state.token=''
            localStorage.removeItem("token")
            state.authenticate= false

        },
        setUser(state,user){
            state.user = user
        }
    },
    actions:{
        async reg(state,object){
            const result = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}register`,object)).data;
            return result
        },
        async authFunc(state,object){
            const result = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}login`,object)).data;
            if(result.connexion)
                state.commit("setToken",result.accessToken);
            return result
        },
        async getData(state,token){
            try{
            const config = {
                headers:{
                    'Authorization' :'Bearer '+token
                }
            }
            const result = (await axios.get(`${process.env.VUE_APP_CURRENT_URL}userdata`,config)).data;
            state.commit('setIsAuth',result);
            return result

            }catch (e) {


                    state.commit('setIsNotAuth')

            }
        },
        async disableBct(state,data){


                const {token,item} = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}disable-bct`,{token:data})).data;
                /*console.log(item)
                state.commit('setUser',item)
                state.commit('setToken',token)*/



        },
        async recoverPWD(state,objet){
            return await axios.post(`${process.env.VUE_APP_CURRENT_URL}recover`, objet)
        },
        async checkTokenResetPassword(state,object){
            const result = await axios.post(`${process.env.VUE_APP_CURRENT_URL}checkrecover-token`,object);
            return result.data
        },
        async resetPWD(state,object){
            return await axios.post(`${process.env.VUE_APP_CURRENT_URL}reset-password`, object)

        },
        async confirmationEmail(state,object){
            const response = await axios.post(`${process.env.VUE_APP_CURRENT_URL}confirmation`,object);
            return response.data
        },
        async setClient(state,object){
            const response = (await axios.post(`${process.env.VUE_APP_CURRENT_URL}set-client`,object))
            state.commit("setTokenPaymentclient",response.data.token)
        },
        async updateClient(state,object){
            try {
                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + object.token
                    }
                }
                const response = (await axios.put(`${process.env.VUE_APP_CURRENT_URL}update-client`, object.field,config))
                state.commit("setToken",response.data.newToken);
                return response.data.client
            }catch (e) {
                console.log(e)
            }

        }
    }
}



